.noShowRules{
    display: none;
}

.hideRules{

    animation-name: hideRules;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.showRules{
    animation-name: showRules;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}



@keyframes hideRules{
    0% {right: 10px; opacity: 1;}
    50%{opacity: 0;}
    100%{right: -700px; opacity: 0;}
}

@keyframes showRules{
    0% {right:-700px; opacity: 0;}
    50%{opacity: 0;}
    100%{right: 10px; opacity: 1;}
}