.checkout-item {
  width: 100%;
  display: grid;
  min-height: 100px;
  padding: 15px 5px;
  align-items: center;
  grid-template-columns: 1fr 1fr 0.5fr 0.5fr 0.5fr 0.25fr;

  .image-container {
    padding-right: 15px;


    img {
      width: 100%;
      height: 100%;
    }
  }


  .title{
    font-size: 16px;
    max-width: 150px;
  }

  .quantity {
  display:flex;
  }

  .arrow
  {
  cursor:pointer;
  }

  .value
  {
    margin: 0 10px;
  }
  .remove-button {
    cursor: pointer;
  }
}



@media only screen and (min-width: 0px) and (max-width: 720px) {
  .checkout-item {
    grid-template-columns: 0.75fr 1fr 0.75fr 0.75fr 0.75fr 0.5fr;
    .title{
      font-size: 12px;
      max-width: 100px;
    }

  }
}
