.checkout-detail-form{
    display: grid;
    justify-content: center;
    grid-row-gap: 10px;
    width: 100%;
    height: 100%;
    align-content: center;

    .form-control{
        width: 97.5%;
        max-width: 95vw;
    }


    .button-group{
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-column-gap: 10px;
    }
}