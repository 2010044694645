.card-header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

}

.details-button-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}