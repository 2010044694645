#simple-dialog-title{
    padding: 5px 5px;
    h2{
        display:grid;
        align-items:center;
        grid-template-columns: auto 1fr;
        column-gap: 10px;
        font-size: 18px;

    }
}

