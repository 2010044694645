.orderProductCard {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;

    .productImageSection {
        width: 100px;
        height: 100%;
        background-size: cover;
        background-position: center center;
    }

    .productDetailSection {
        display: grid;
        row-gap: 8px;
        padding: 16px;
        
    }
}

@media only screen and (max-width: 500px) {
    .orderProductCard {
        grid-template-columns: 1fr;
        .productImageSection {
            width: 100%;
            height: 150px
        }

        .productDetailSection {
            margin: 5px 0;

            h5{
                margin-bottom: 5px;
            }
            
        }
    }
}
