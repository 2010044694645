@keyframes chipAnim {
    0% {top: 0}
    25% {top: -10px}
    50% {top: 0}
    75% {top: -10px}
    100% {top: 0}

}


.animated-chip{
animation-name: chipAnim;
animation-duration: 500ms;
animation-timing-function: ease-in-out;
animation-fill-mode: forwards;
animation-delay: 50ms;
animation-iteration-count: 2;
}