.checkout-page {
  display: grid;
  align-content: start;
  align-items: start;
  min-height: 500px;

  .checkout-header {
    width: 100%;
    padding: 10px 0;
    display: grid;
    padding: 10px;
    grid-template-columns: 1fr 1fr 0.5fr 0.5fr 0.5fr 0.25fr;
    justify-content: space-between;

    .header-block {
      text-transform: capitalize;
      width: 23%;

      &:last-child {
        width: 8%;
      }
    }
  }

  .total {
    margin-top: 30px;
    margin-left: auto;
  }

  .footer-block{
    display:grid;
    justify-items: end;
    grid-row-gap: 10px;
  .checkout-button{
    justify-self: end;
  }
}
}




@media only screen and (min-width: 0px) and (max-width: 720px) {
  .checkout-page {
    

    .checkout-header{
      grid-template-columns: 0.75fr 1fr 0.75fr 0.75fr 0.75fr 0.5fr;
    }
    & *
    {
      font-size: 0.75rem;
    }

  }
}
