.CheckOutItemCard{
    display:grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    min-height: 200px;


    .productImageSection{
        width: 125px;
        height: auto;
        background-size: cover;
        background-position: center center;

    }

    .quantity{
        display: flex;
        align-items: center;

        &>*{
            margin-right: 10px;
        }
    }

    .productDetailSection{
        display: grid;
        row-gap: 12px;
        padding: 16px;
    }
}

@media only screen and (max-width: 500px) {
    .CheckOutItemCard {
        grid-template-columns: 1fr;
        .productImageSection {
            width: 100%;
            height: 150px
        }

        .productDetailSection {
            margin: 5px 0;
            padding: 12px;

            h5{
                margin-bottom: 5px;
            }
            
        }
    }
}